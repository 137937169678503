import { useNavigate, useParams } from "react-router-dom";
import SimilarArticleDrawer from "./similarArticleDrawer";

const SimilarArticlesPage = () => {
  const navigate = useNavigate();
  const { id, title } = useParams();

  const handleDrawerOpen = () => {
    navigate(`/articles/${id}/${title}`);
  };

  const handleDrawerClose = () => {
    navigate("/");
  };

  return (
    <SimilarArticleDrawer
      showDrawer={true}
      handleDrawerOpen={handleDrawerOpen}
      handleDrawerClose={handleDrawerClose}
      selectedArticleDocId={id || ""}
      selectedArticleTitle={title || ""}
      handleCategorySelect={() => {}}
      handleCountrySelect={() => {}}
      handleSiteSelect={() => {}}
    />
  );
};

export default SimilarArticlesPage;
