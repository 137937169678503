import { Box, CardMedia, Typography, IconButton, Link } from "@mui/material";
import React, { useState } from "react";
import { Article } from "../../../types";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
interface CurrentArticleCardProps {
  currentArticle: Article | null;
}

const CurrentArticleCard: React.FC<CurrentArticleCardProps> = ({
  currentArticle,
}) => {
  const formatDateAndTime = (date: string) => {
    const dateObj = new Date(date);
    return (
      dateObj.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }) +
      " " +
      dateObj.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      })
    );
  };

  const [imageError, setImageError] = useState<boolean>(false);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {currentArticle && (
        <Box
          sx={{
            display: "flex",
            p: 2,
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",

              borderRadius: {
                xs: "20px",
                md: "20px 0 0 20px",
              },
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: {
                  xs: "20px",
                  md: "20px 0 0 20px",
                },
              }}
              image={imageError ? "/404.jpg" : currentArticle.top_image}
              alt={currentArticle.title}
              onError={(event) => {
                if (!imageError) {
                  const target = event.target as HTMLImageElement;
                  target.src = "/404.jpg";
                  setImageError(true);
                }
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "1rem",
              width: "calc(100% - 2rem)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontSize: "1.8em",
                  }}
                >
                  {currentArticle.title}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    sx={{
                      color: "#1976d2",
                      fontSize: "2em",
                    }}
                  >
                    <BookmarkBorderIcon sx={{ fontSize: "inherit" }} />
                  </IconButton>
                </Box>
              </Box>

              <Typography
                variant="caption"
                sx={{ color: "#888", fontSize: "1.2em" }}
              >
                {formatDateAndTime(currentArticle.publish_date)}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  marginRight: "auto",
                  color: "#1976d2",
                  fontSize: "1.2em",
                }}
              >
                {currentArticle.site}
                <IconButton
                  component={Link}
                  href={currentArticle.parent_url}
                  target="_blank"
                  sx={{
                    color: "#1976d2",
                    fontSize: "1.2em",
                  }}
                >
                  <OpenInNewIcon sx={{ fontSize: "inherit" }} />
                </IconButton>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#5b5b5b",
                  fontSize: "1.1em",
                  mt: 2,
                  fontWeight: 600,
                }}
              >
                {currentArticle.summary}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CurrentArticleCard;
