import React, { useState, useEffect } from "react";
import { ApiResponse } from "../types";

export const useGetSingleArticle = (docId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [articles, setArticles] = useState<ApiResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLatest = async () => {
      setLoading(true);
      try {
        const apiUrl = process.env.REACT_APP_API_URL;

        let url = `${apiUrl}/api/article/${docId}/`;

        const response = await fetch(url);
        const data: ApiResponse = await response.json();
        setArticles(data);
      } catch (error: any) {
        setArticles(null);
      } finally {
        setLoading(false);
      }
    };

    if (docId !== "") fetchLatest();
  }, [docId]);

  return { loading, articles, error };
};
