import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  Snackbar,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CurrentArticleCard from "./currentArticleCard";
import { useGetSingleArticle } from "../../../hooks/useGetSingleArticle";
import { useGetArticlesWithParams } from "./utils";
import ArticleList from "./articleList";

import {
  LinkedIn as LinkedInIcon,
  Reddit as RedditIcon,
  Twitter as TwitterIcon,
  WhatsApp as WhatsAppIcon,
  Link as LinkIcon,
} from "@mui/icons-material";
import Sidebar from "../../../components/sidebar";
import Navbar from "../../../components/navbar";
import NewsStatistics from "./NewsStatistics";
interface SimilarArticleDrawerProps {
  showDrawer: boolean;
  handleDrawerClose: () => void;
  selectedArticleDocId: string;
  selectedArticleTitle: string;
  handleDrawerOpen: (articleId: string, articleTitle: string) => void;
  handleCategorySelect: (category: string) => void;
  handleCountrySelect: (country: string) => void;
  handleSiteSelect: (site: string) => void;
}

const SimilarArticleDrawer: React.FC<SimilarArticleDrawerProps> = ({
  showDrawer,
  handleDrawerClose,
  selectedArticleDocId,
  selectedArticleTitle,
  handleDrawerOpen,
  handleCategorySelect,
  handleCountrySelect,
  handleSiteSelect,
}) => {
  const [allSimilarLoading, setAllSimilarLoading] = useState<boolean>(true);

  // Refs for scrolling and intersection observer
  const refs = {
    mostSimilar: {
      box: React.createRef<HTMLDivElement>(),
      card: React.createRef<HTMLDivElement>(),
    },
    moreSimilar: {
      box: React.createRef<HTMLDivElement>(),
      card: React.createRef<HTMLDivElement>(),
    },
    similar: {
      box: React.createRef<HTMLDivElement>(),
      card: React.createRef<HTMLDivElement>(),
    },
  };

  const [similarSelectedCategories, setSimilarSelectedCategories] = useState<
    string[]
  >([]);
  const [similarSelectedCountries, setSimilarSelectedCountries] = useState<
    string[]
  >([]);
  const [similarSelectedSites, setSimilarSelectedSites] = useState<string[]>(
    []
  );

  const [page, setPage] = useState({
    mostSimilar: 1,
    moreSimilar: 1,
    similar: 1,
  });

  const [sortOrder, setSortOrder] = useState({
    mostSimilar: "desc",
    moreSimilar: "desc",
    similar: "desc",
  });

  const [fetchParams, setFetchParams] = useState({
    docId: selectedArticleDocId,
    sortBy: "publish_date",
    language: "en",
    search: "",
    selectedCategories: similarSelectedCategories,
    selectedCountries: similarSelectedCountries,
    selectedSites: similarSelectedSites,
  });

  const scrollToStart = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollLeft = 0;
    }
  };

  const handleSortOrderChange = (
    event: any,
    type: "mostSimilar" | "moreSimilar" | "similar"
  ) => {
    const value = event.target.value as string;

    scrollToStart(refs[type].box);

    setSortOrder((prev) => ({
      ...prev,
      [type]: value,
    }));

    setPage((prev) => ({
      ...prev,
      [type]: 1,
    }));
  };

  useEffect(() => {
    setAllSimilarLoading(true);

    setPage({
      mostSimilar: 1,
      moreSimilar: 1,
      similar: 1,
    });

    setSortOrder({
      mostSimilar: "desc",
      moreSimilar: "desc",
      similar: "desc",
    });

    setFetchParams({
      docId: selectedArticleDocId,
      sortBy: "publish_date",
      language: "en",
      search: "",
      selectedCategories: similarSelectedCategories,
      selectedCountries: similarSelectedCountries,
      selectedSites: similarSelectedSites,
    });
  }, [selectedArticleDocId]);

  const {
    loading: loadingSimilar,
    articles: similarArticles,
    error: errorSimilar,
  } = useGetArticlesWithParams({
    ...fetchParams,
    page: page.similar,
    sortType: sortOrder.similar,
    similarity: 1.5,
  });

  const {
    loading: loadingMoreSimilar,
    articles: moreSimilarArticles,
    error: errorMoreSimilar,
  } = useGetArticlesWithParams({
    ...fetchParams,
    page: page.moreSimilar,
    sortType: sortOrder.moreSimilar,
    similarity: 1.6,
  });

  const {
    loading: loadingMostSimilar,
    articles: mostSimilarArticles,
    error: errorMostSimilar,
  } = useGetArticlesWithParams({
    ...fetchParams,
    page: page.mostSimilar,
    sortType: sortOrder.mostSimilar,
    similarity: 1.7,
  });

  const {
    loading: loadingCurrentArticle,
    articles: currentArticle,
    error: errorCurrentArticle,
  } = useGetSingleArticle(selectedArticleDocId);

  const loadMoreArticles = (
    type: "mostSimilar" | "moreSimilar" | "similar"
  ) => {
    setPage((prev) => ({
      ...prev,
      [type]: prev[type] + 1,
    }));
  };

  // Intersection observer for loading more articles

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !allSimilarLoading) {
          if (entry.target === refs.mostSimilar.card.current) {
            loadMoreArticles("mostSimilar");
          } else if (entry.target === refs.moreSimilar.card.current) {
            loadMoreArticles("moreSimilar");
          } else if (entry.target === refs.similar.card.current) {
            loadMoreArticles("similar");
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 1,
    });

    if (refs.mostSimilar.card.current && !loadingMostSimilar)
      observer.observe(refs.mostSimilar.card.current);
    if (refs.moreSimilar.card.current && !loadingMoreSimilar)
      observer.observe(refs.moreSimilar.card.current);
    if (refs.similar.card.current && !loadingSimilar)
      observer.observe(refs.similar.card.current);

    return () => {
      if (refs.mostSimilar.card.current)
        observer.unobserve(refs.mostSimilar.card.current);
      if (refs.moreSimilar.card.current)
        observer.unobserve(refs.moreSimilar.card.current);
      if (refs.similar.card.current)
        observer.unobserve(refs.similar.card.current);
    };
  }, [allSimilarLoading, loadMoreArticles]);

  // Load more articles from which index
  const [loadMoreLastIndex, setLoadMoreLastIndex] = useState<number>(5);

  const updateLoadMoreLastIndex = useCallback(() => {
    if (window.screen.width < 600) {
      setLoadMoreLastIndex(5);
    } else if (window.screen.width < 1200) {
      setLoadMoreLastIndex(8);
    } else {
      setLoadMoreLastIndex(10);
    }
  }, []);

  useEffect(() => {
    updateLoadMoreLastIndex();
    window.addEventListener("resize", updateLoadMoreLastIndex);
    return () => window.removeEventListener("resize", updateLoadMoreLastIndex);
  }, [updateLoadMoreLastIndex]);

  // Set all similar loading to false when all similar articles are loaded

  useEffect(() => {
    if (
      !loadingSimilar &&
      !loadingMoreSimilar &&
      !loadingMostSimilar &&
      !loadingCurrentArticle
    ) {
      setAllSimilarLoading(false);
    }
  }, [
    loadingSimilar,
    loadingMoreSimilar,
    loadingMostSimilar,
    loadingCurrentArticle,
  ]);

  // Snackbar for showing link copied message
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const handleCopyLink = () => {
    const encodedTitle = encodeURIComponent(selectedArticleTitle);
    const shareableLink = `${window.location.origin}/articles/${selectedArticleDocId}/${encodedTitle}`;
    navigator.clipboard.writeText(shareableLink);
    setSnackbarOpen(true);

    setTimeout(() => {
      setSnackbarOpen(false);
    }, 1500);
  };

  const [snackbarMessage, setSnackbarMessage] = useState("");

  const currentUrl = window.location.href;
  const title = selectedArticleTitle;

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      title + " " + currentUrl
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleLinkedInShare = () => {
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      currentUrl
    )}`;
    window.open(linkedinUrl, "_blank");
  };

  const handleRedditShare = () => {
    const redditUrl = `https://reddit.com/submit?url=${encodeURIComponent(
      currentUrl
    )}&title=${encodeURIComponent(title)}`;
    window.open(redditUrl, "_blank");
  };

  const handleXShare = () => {
    const xUrl = `https://x.com/intent/tweet?url=${encodeURIComponent(
      currentUrl
    )}&text=${encodeURIComponent(title)}`;
    window.open(xUrl, "_blank");
  };

  return (
    <Drawer anchor="right" open={showDrawer} onClose={handleDrawerClose}>
      <Box
        sx={{
          position: "relative",
          width: {
            xs: "100vw",
            md: "100vw",
          },
          background: "white",
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            top: "auto",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#4fabca",
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box sx={{ pr: 4 }}>
              <IconButton
                color="inherit"
                aria-label="share on whatsapp"
                title="Share on WhatsApp"
                onClick={handleWhatsAppShare}
              >
                <WhatsAppIcon />
              </IconButton>
              {/* <IconButton
                color="inherit"
                aria-label="share on linkedin"
                title="Share on LinkedIn"
                onClick={handleLinkedInShare}
              >
                <LinkedInIcon />
              </IconButton> */}
              <IconButton
                color="inherit"
                aria-label="share on reddit"
                title="Share on Reddit"
                onClick={handleRedditShare}
              >
                <RedditIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="share on x"
                title="Share on X"
                onClick={handleXShare}
              >
                <TwitterIcon />
              </IconButton>

              <IconButton
                color="inherit"
                aria-label="copy url"
                title="Copy URL"
                onClick={handleCopyLink}
              >
                <LinkIcon />
              </IconButton>
            </Box>
            <IconButton
              color="inherit"
              aria-label="close"
              title="Close"
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            mb: 10,
          }}
        >
          {allSimilarLoading ? (
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                paddingBottom: "1rem",
              }}
            >
              {errorSimilar ||
              errorMoreSimilar ||
              errorMostSimilar ||
              errorCurrentArticle ? (
                <Typography
                  sx={{
                    fontSize: "calc(1rem + .3vw)",
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  Something went wrong! Please try again.
                </Typography>
              ) : (
                <Fragment>
                  <CurrentArticleCard
                    currentArticle={currentArticle?.documents[0] || null}
                  />

                  {(mostSimilarArticles
                    ? mostSimilarArticles?.total_count
                    : 0) +
                    (moreSimilarArticles
                      ? moreSimilarArticles?.total_count
                      : 0) +
                    (similarArticles ? similarArticles?.total_count : 0) ===
                  0 ? (
                    <Typography
                      sx={{
                        fontSize: "calc(1rem + .3vw)",
                        fontWeight: "bold",
                      }}
                    >
                      Still Munching...
                    </Typography>
                  ) : (
                    <Fragment>
                      {[
                        {
                          title: "Similar",
                          articles: mostSimilarArticles,
                          loading: loadingMostSimilar,
                          page: page.mostSimilar,
                          refs: refs.mostSimilar,
                          sortOrder: sortOrder.mostSimilar,
                          component: "mostSimilar" as const,
                        },
                        {
                          title: "Related",
                          articles: moreSimilarArticles,
                          loading: loadingMoreSimilar,
                          page: page.moreSimilar,
                          refs: refs.moreSimilar,
                          sortOrder: sortOrder.moreSimilar,
                          component: "moreSimilar" as const,
                        },
                        {
                          title: "Discover",
                          articles: similarArticles,
                          loading: loadingSimilar,
                          page: page.similar,
                          refs: refs.similar,
                          sortOrder: sortOrder.similar,
                          component: "similar" as const,
                        },
                      ].map(
                        ({
                          title,
                          articles,
                          loading,
                          page,
                          refs,
                          sortOrder,
                          component,
                        }) =>
                          articles &&
                          articles.total_count > 0 && (
                            <ArticleList
                              key={title}
                              title={title}
                              component={component}
                              articles={articles}
                              loading={loading}
                              page={page}
                              refs={refs}
                              sortOrder={sortOrder}
                              loadMoreLastIndex={loadMoreLastIndex}
                              loadMoreArticles={() =>
                                loadMoreArticles(component)
                              }
                              handleSortOrderChange={handleSortOrderChange}
                              handleCategorySelect={handleCategorySelect}
                              handleCountrySelect={handleCountrySelect}
                              handleSiteSelect={handleSiteSelect}
                              handleDrawerOpen={handleDrawerOpen}
                            />
                          )
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        onClose={() => {}}
        message="Copied to clipboard!"
        autoHideDuration={1500}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#3693b2",
            color: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
          },
        }}
      />
    </Drawer>
  );
};

export default SimilarArticleDrawer;
