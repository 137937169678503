import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login as storeLogin, UserInterface } from "../redux/store/authSlice";
import { useAppDispatch } from "../redux/store/store";
import authService from "../userAPI/auth";
import { useNavigate } from "react-router-dom";

export default function AuthPopUp({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    const formData = new FormData(event.currentTarget);
    const { email, username, password } = Object.fromEntries(formData);

    try {
      if (isLogin) {
        await authService.login(username as string, password as string);
      } else {
        await authService.signUp(
          email as string,
          username as string,
          password as string
        );
      }

      const userData = await authService.getCurrentUser();
      if (userData && isUserInterface(userData)) {
        dispatch(storeLogin(userData));
        onClose();
        navigate("/");
      }
    } catch (error) {
      setError(
        error instanceof Error
          ? error.message
          : "An unexpected error occurred. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box sx={{ display: "flex", maxHeight: "500px" }}>
        {/* <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "linear-gradient(135deg, #2B7B96 0%, #72B5B7 100%)",
            color: "white",
            padding: 4,
          }}
        >
          <Typography variant="h3" fontWeight="bold" mb={2}>
            {isLogin ? "Welcome Back" : "Join Us"}
          </Typography>
          <Typography variant="h6" textAlign="center">
            {isLogin
              ? "Log in to access your personalized experience"
              : "Sign up to start your journey with us"}
          </Typography>
        </Box> */}
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 4,
          }}
        >
          <Typography variant="h4" mb={3} fontWeight="bold">
            {isLogin ? "Login" : "Sign Up"}
          </Typography>
          {!isLogin && (
            <TextField
              required
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              type="email"
              margin="normal"
            />
          )}
          <TextField
            required
            fullWidth
            id="username"
            name="username"
            label="Username"
            margin="normal"
          />
          <TextField
            required
            fullWidth
            id="password"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Typography color="error" variant="body2" mt={1}>
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: "#2B7B96",
              "&:hover": { bgcolor: "#72B5B7" },
            }}
            disabled={loading}
          >
            {loading ? "Processing..." : isLogin ? "Login" : "Sign Up"}
          </Button>
          <Typography variant="body2" align="center">
            {isLogin ? "Don't have an account?" : "Already have an account?"}
            <Button color="primary" onClick={() => setIsLogin(!isLogin)}>
              {isLogin ? "Sign Up" : "Login"}
            </Button>
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
}

function isUserInterface(obj: any): obj is UserInterface {
  return (
    typeof obj === "object" &&
    typeof obj.id === "number" &&
    typeof obj.username === "string" &&
    typeof obj.email === "string" &&
    typeof obj.dateJoined === "string" &&
    typeof obj.isActive === "boolean"
  );
}
