import { MenuOpen, Search } from "@mui/icons-material";
import { Box, CardMedia, IconButton, Typography } from "@mui/material";
import Headroom from "react-headroom";
import { Link } from "react-router-dom";

interface NavbarProps {
  toggleSidebar: (focusOnSearch?: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ toggleSidebar }) => {
  return (
    <Headroom pinStart={0}>
      <Box
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
          justifyContent: "space-between",
          alignItems: "center",

          backgroundColor: "#3693b2",
          padding: "8px",
          color: "white",
          zIndex: "100",
        }}
      >
        <Link
          to="/"
          style={{
            display: "flex",
            gap: ".5rem",
            alignItems: "center",
            fontSize: "1.5rem",
            color: "white",
            textDecoration: "none",
          }}
        >
          <CardMedia
            component="img"
            image="/logo192.png"
            alt="logo"
            sx={{
              width: "40px",
              borderRadius: "50%",
            }}
          />
          News Muncher
        </Link>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{ color: "white" }}
            onClick={() => {
              toggleSidebar(true);
            }}
            title="search"
          >
            <Search />
          </IconButton>

          <IconButton
            sx={{ color: "white" }}
            onClick={() => {
              toggleSidebar(false);
            }}
            title="menu"
          >
            <MenuOpen />
          </IconButton>
        </Box>
      </Box>
    </Headroom>
  );
};

export default Navbar;
