import { createSlice,PayloadAction } from "@reduxjs/toolkit";

export interface BookmarkInterface {
  article_id: string;
  user: number;
  timestamp:string;
}

interface bookmarksState {
  status: boolean;
  bookmarks: BookmarkInterface[] | null;
}

const initialState:bookmarksState = {
  status: false,
  bookmarks: null,
};

const bookmarkSlice = createSlice({
  name: "bookmarks",
  initialState,
  reducers: {
    addBookmark: (state, action: PayloadAction<BookmarkInterface[]>) => {
      state.status = true;
      state.bookmarks = action.payload;
    },
    deleteBookmark: (state) => {
      state.status = false;
      state.bookmarks = null;
    },
  },
});

export default bookmarkSlice.reducer;
export const { addBookmark, deleteBookmark } = bookmarkSlice.actions;
