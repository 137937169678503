import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import ArticleCard from "../../../components/card";
import DummyCard from "../../../components/dummycard";

interface BodyProps {
  articles: any;
  loading: boolean;
  error: any;
  handleCategorySelect: (category: string) => void;
  handleCountrySelect: (country: string) => void;
  handleSiteSelect: (site: string) => void;
  handleDrawerOpen: (docId: string, title: string) => void;
  loadMoreArticles: () => void;
  loadMoreRef: React.RefObject<HTMLButtonElement>;
}

const Body: React.FC<BodyProps> = ({
  articles,
  loading,
  error,
  handleCategorySelect,
  handleCountrySelect,
  handleSiteSelect,
  handleDrawerOpen,
  loadMoreArticles,
  loadMoreRef,
}) => {
  return (
    <>
      <Grid container spacing={4}>
        {articles?.documents &&
          articles.documents.map((article: any, index: number) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={4} key={article.id}>
                <ArticleCard
                  article={article}
                  onCategorySelect={handleCategorySelect}
                  onCountrySelect={handleCountrySelect}
                  onSiteSelect={handleSiteSelect}
                  handleDrawerOpen={handleDrawerOpen}
                />
              </Grid>
            );
          })}

        {articles && articles?.total_count === 0 && !loading && (
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              No articles found
            </Typography>
          </Grid>
        )}

        {loading &&
          [...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
              <DummyCard styles={{ maxWidth: "100%" }} />
            </Grid>
          ))}

        {error && (
          <Grid item xs={12}>
            <p>Error fetching articles: {error}</p>
          </Grid>
        )}
      </Grid>
      {articles &&
        articles?.total_pages > 0 &&
        articles?.current_page !== articles?.total_pages && (
          <Button
            onClick={loadMoreArticles}
            ref={loadMoreRef}
            disabled={loading}
            variant="contained"
            color="primary"
            style={{
              margin: "2rem 0",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Load More
          </Button>
        )}
    </>
  );
};

export default Body;
