import React from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  Paper,
} from "@mui/material";
import ArticleCard from "../../../components/card";
import { ApiResponse } from "../../../types";
import NewsStatistics from "./NewsStatistics";

interface ArticleListProps {
  title: string;
  component: string;
  articles: ApiResponse;
  loading: boolean;
  page: number;
  refs: any;
  sortOrder: string;
  handleSortOrderChange: (
    event: any,
    type: "mostSimilar" | "moreSimilar" | "similar"
  ) => void;
  loadMoreArticles: () => void;
  loadMoreLastIndex: number;
  handleCategorySelect: (category: string) => void;
  handleCountrySelect: (country: string) => void;
  handleSiteSelect: (site: string) => void;
  handleDrawerOpen: (articleId: string, articleTitle: string) => void;
}

const ArticleList: React.FC<ArticleListProps> = ({
  title,
  component,
  articles,
  loading,
  page,
  refs,
  sortOrder,
  loadMoreArticles,
  loadMoreLastIndex,
  handleSortOrderChange,
  handleCategorySelect,
  handleCountrySelect,
  handleSiteSelect,
  handleDrawerOpen,
}) => {
  return (
    <Paper elevation={1} sx={{ m: 1 }}>
      <Box
        sx={{
          display: `${articles?.total_count > 0 ? "flex" : "none"}`,
          flexDirection: "column",
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem",
            borderRadius: "8px",
          }}
        >
          <Typography
            sx={{
              fontSize: "calc(1.2rem + .3vw)",
              fontWeight: "bold",
              color: "black",
            }}
          >
            {title}
          </Typography>
          <FormControl
            variant="outlined"
            size="small"
            sx={{ minWidth: "120px", marginRight: "1rem" }}
          >
            <InputLabel
              sx={{
                color: "#333",
                fontSize: "0.9rem",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              Sort by
            </InputLabel>
            <Select
              value={sortOrder}
              onChange={(e) => handleSortOrderChange(e, component as any)}
              label="Sort by"
              sx={{
                backgroundColor: "#FFF",
                borderRadius: "8px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#999",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#666",
                },
                "& .MuiSelect-icon": {
                  color: "#333",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  },
                },
              }}
            >
              <MenuItem value="desc">Newest first</MenuItem>
              <MenuItem value="asc">Oldest first</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            overflowX: "auto",
            overflowY: "hidden",
          }}
          ref={refs.box}
        >
          <Box
            sx={{
              display: "grid",
              gridAutoColumns: "max-content",
              gridTemplateRows: "1fr",
              width: "max-content",
              gap: "1rem",
              paddingBottom: "1rem",
            }}
          >
            {articles?.documents.map((article, index) => {
              const isLoadMoreRef =
                page < articles.total_pages &&
                index === articles.documents.length - loadMoreLastIndex;

              return (
                <Box
                  key={index}
                  sx={{
                    gridRow: 1,
                    gridColumn: "auto",
                    maxWidth: {
                      xs: "250px",
                      md: "300px",
                    },
                  }}
                  ref={isLoadMoreRef ? refs.card : null}
                >
                  <ArticleCard
                    article={article}
                    small={true}
                    onCategorySelect={handleCategorySelect}
                    onCountrySelect={handleCountrySelect}
                    onSiteSelect={handleSiteSelect}
                    handleDrawerOpen={handleDrawerOpen}
                  />
                </Box>
              );
            })}
          </Box>

          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
                maxHeight: "31em",
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {articles?.total_pages > 0 && page !== articles?.total_pages && (
            <Button
              onClick={loadMoreArticles}
              disabled={loading}
              variant="contained"
              color="primary"
              style={{
                minWidth: "fit-content",
                height: "fit-content",
              }}
            >
              Load More
            </Button>
          )}
        </Box>
        {articles && <NewsStatistics documents={articles.documents} />}
      </Box>
    </Paper>
  );
};

export default ArticleList;
