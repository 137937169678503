import { createSlice,PayloadAction } from "@reduxjs/toolkit";

export interface UserInterface {
  id: number;
  username: string;
  email: string;
  dateJoined: string;
  isActive: boolean;
}

interface AuthState {
  status: boolean;
  user: UserInterface | null;
}

const initialState:AuthState = {
  status: false,
  user: null,
};

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserInterface>) => {
      state.status = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.status = false;
      state.user = null;
    },
  },
});

export default authSlice.reducer;
export const { login, logout } = authSlice.actions;
