import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1c5ae3",
      light: "#4A7BE8",
    },
    secondary: {
      main: "#0F46BC",
      light: "#0F9DBC",
    },
  },
  typography: {
    h2: {
      fontSize: "4rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "2rem",
      fontWeight: 500,
    },
  },
});

export default theme;
