import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

interface HeaderProps {
  pageName: string;
  search: string;
  selectedCategories: string[];
  sortType: string;
  setSortType: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<HeaderProps> = ({
  pageName,
  search,
  selectedCategories,
  sortType,
  setSortType,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          padding: "1rem 0",
          fontSize: "calc(1.5rem + .8vw)",
          fontWeight: "bold",
          color: "#34495e",
          textTransform: "capitalize",
          wordBreak: "break-word",
        }}
      >
        {pageName === "search" &&
          (search !== ""
            ? `Search Results for "${search}"`
            : "Latest Articles")}

        {/* {pageName === "category" &&
          (selectedCategories.length > 0 && selectedCategories[0].length > 0
            ? selectedCategories[0]
            : "Latest Articles")} */}

        {pageName === "category" &&
        //element 0 become null, get error "Cannot read properties of null (reading 'length')"
        selectedCategories[0] == null
          ? "Latest Articles"
          : selectedCategories}
      </Typography>

      {/* <FormControl variant="outlined" size="small">
        <InputLabel>Sort</InputLabel>
        <Select
          value={sortType}
          onChange={(e) => setSortType(e.target.value as string)}
          label="Sort"
        >
          <MenuItem value="desc">Newest first</MenuItem>
          <MenuItem value="asc">Oldest first</MenuItem>
        </Select>
      </FormControl> */}
    </Box>
  );
};

export default Header;
