import { Box, CardMedia, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Search from "./search";
import { useAppSelector, useAppDispatch } from "../redux/store/store";
import { RootState } from "../redux/store/store";
import authService from "../userAPI/auth";
import { logout as storeLogout } from "../redux/store/authSlice";
import AuthPopUp from "./authPopUp";

// export const mainCategories = [
//   "trending",
//   "adventure",
//   "ai",
//   "alliance",
//   "auto",
//   "aviation",
//   "biodiversity",
//   "brexit",
//   "censorship",
//   "climate",
//   "corruption",
//   "crypto",
//   "diplomacy",
//   "divestment",
//   "education",
//   "elections",
//   "encryption",
//   "entrepreneurship",
//   "espionage",
//   "esports",
//   "economy",
//   "ecosystem",
//   "entertainment",
//   "equity",
//   "extradition",
//   "fintech",
//   "finance",
//   "food",
//   "gas",
//   "geopolitics",
//   "govt",
//   "gourmet",
//   "hacking",
//   "health",
//   "innovation",
//   "investment",
//   "judiciary",
//   "luxury",
//   "markets",
//   "migration",
//   "money",
//   "nationalism",
//   "oil",
//   "organic",
//   "outreach",
//   "philanthropy",
//   "politics",
//   "populism",
//   "privacy",
//   "protests",
//   "regulations",
//   "recession",
//   "robotics",
//   "sanctions",
//   "scandal",
//   "sport",
//   "stocks",
//   "subsidies",
//   "sustainability",
//   "surveillance",
//   "tech",
//   "terrorism",
//   "trade",
//   "travel",
//   "venture",
// ];

export const mainCategories = [
  "top",
  "trending",
  "scandal",
  "corruption",
  "terror",
  "ai",
  "stocks",
  "market",
  "judiciary",
  "courts",
  "law",
  "delhi",
  "cars",
  "unrest",
  "crpf",
  "ed",
  "arrest",
  "case",
  "bail",
  "politics",
  "world",
  "bollywood",
  "money",
  "govt",
  "economy",
  "surveillance",
  "controversy",
  "army",
  "conspiracy",
  "leaks",
  "hacking",
  "protest",
  "riots",
  "election",
  "fraud",
  "cybercrime",
  "drugs",
  "espionage",
  "propaganda",
  "censorship",
  "rebellion",
  "whistleblower",
  "blackmail",
  "bribery",
  "extremism",
  "embezzlement",
  "cult",
  "assassination",
  "coup",
  "smuggling",
  "scam",
  "murder",
  "genocide",
  "war",
  "climate",
  "pandemic",
  "discrimination",
  "scandal",
  "privacy",
  "misinformation",
];
interface SidebarProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  focusOnSearch: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  isSidebarOpen,
  toggleSidebar,
  focusOnSearch,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const path = location.pathname;
  const urlParams = new URLSearchParams(location.search);
  const urlCategory = urlParams.get("category");
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [loginpopUp, setLoginpopUp] = useState<boolean>(false);

  //from Store

  const user = useAppSelector((state: RootState) => {
    return state.authReducer.user;
  });

  const logout = async () => {
    try {
      const out = await authService.logout();
      if (!out) {
        console.log("Logout Failed");
        return;
      }
      dispatch(storeLogout());
    } catch (error) {
      console.log("Error while logout.");
    }
  };

  useEffect(() => {
    if (path === "/categories") {
      setSelectedCategory(urlCategory);
    } else {
      setSelectedCategory(null);
    }
  }, [path, urlCategory]);

  const handleCategoryClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const category = (event.target as HTMLElement).id;
    if (category) {
      navigate(`/categories?category=${category}`);
      toggleSidebar();
    }
  };

  return (
    <>
      {loginpopUp && (
        <AuthPopUp open={loginpopUp} onClose={() => setLoginpopUp(false)} />
      )}
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          maxWidth: "250px",
          height: "100vh",
          position: "fixed",
          left: {
            md: "0",
          },
          right: isSidebarOpen ? "0" : "-100%",
          top: "0",
          overflowX: "hidden",
          overflowY: "auto",
          padding: "1rem",
          borderRight: "1px solid #e0e0e0",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          background: "rgb(253, 255, 255)",
          transition: "all 0.3s ease-in-out",
          zIndex: "101",

          "&::-webkit-scrollbar-thumb": {
            background: "#d0d0d0",
          },

          "&::-webkit-scrollbar-track": {
            background: "#f5f7fa",
          },
        }}
        onClick={handleCategoryClick}
      >
        <Link
          to="/"
          style={{
            display: "flex",
            gap: ".5rem",
            alignItems: "center",
            fontSize: "1.5rem",
            color: "black",
            textDecoration: "none",
          }}
        >
          <CardMedia
            component="img"
            image="/logo192.png"
            alt="logo"
            sx={{
              width: "40px",
              borderRadius: "50%",
            }}
          />
          News Muncher
        </Link>

        <Divider />

        <Search toggleSidebar={toggleSidebar} focusOnSearch={focusOnSearch} />

        {user ? (
          <Box>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              // alignItems="center"
              justifyContent="space-evenly"
            >
              <Typography mb="5px">{user.username}</Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "stretch",
                  gap: "10px",
                }}
                mb="5px"
              >
                <Box
                  sx={{
                    padding: "8px",
                    cursor: "pointer",
                    transition: "all 0.2s",
                    fontSize: "1rem",
                    color: "#555555",
                    background: "rgba(0, 0, 0, 0.05)",
                    borderRadius: "8px",
                    margin: "2px 0",
                    "&:hover": {
                      background: "#3693b2",
                      color: "#ffffff",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
                    },
                  }}
                  onClick={() => navigate("/bookmarks")}
                >
                  My Bookmarks
                </Box>
                <Box
                  sx={{
                    padding: "8px",
                    cursor: "pointer",
                    transition: "all 0.2s",
                    fontSize: "1rem",
                    color: "#fff",
                    background: "rgba(0, 0, 0, 1)",
                    borderRadius: "8px",
                    margin: "2px 0",
                    "&:hover": {
                      background: "red",
                      color: "#ffffff",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
                    },
                  }}
                  onClick={logout}
                >
                  Logout
                </Box>
              </Box>
            </Box>
            <Divider />
          </Box>
        ) : (
          <Box
            sx={{
              padding: "8px",
              cursor: "pointer",
              transition: "all 0.2s",
              fontSize: "1rem",
              color: "#555555",
              background: "rgba(0, 0, 0, 0.05)",
              borderRadius: "8px",
              margin: "2px 0",
              "&:hover": {
                background: "#3693b2",
                color: "#ffffff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
              },
            }}
            onClick={() => setLoginpopUp(true)}
          >
            Login
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#555555",
              fontWeight: "bold",
              paddingLeft: ".5rem",
            }}
          >
            All Categories
          </Typography>

          <IconButton
            sx={{
              visibility: {
                xs: "visible",
                md: "hidden",
              },
              borderRadius: "10px",
              p: 1,
              background: "#3693b2",
              color: "white",
              zIndex: "102",

              "&:hover": {
                background: "#3693b2",
              },
            }}
            onClick={toggleSidebar}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          {mainCategories.map((category, index) => (
            <Box
              key={index}
              id={category}
              sx={{
                padding: "8px",
                cursor: "pointer",
                transition: "all 0.2s",
                fontSize: "1rem",
                color: selectedCategory === category ? "#ffffff" : "#555555",
                background:
                  selectedCategory === category
                    ? "#3693b2"
                    : "rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                margin: "2px 0",
                boxShadow:
                  selectedCategory === category
                    ? "0 2px 4px rgba(0, 0, 0, 0.2)"
                    : "none",
                "&:hover": {
                  background: "#3693b2",
                  color: "#ffffff",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              {category.toUpperCase()}
            </Box>
          ))}
        </Box>
      </Box>

      {/* Overlay */}
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100vw",
          height: "100vh",
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "100",
          display: {
            xs: isSidebarOpen ? "block" : "none",
            md: "none",
          },
        }}
        onClick={toggleSidebar}
      />
    </>
  );
};

export default Sidebar;
