import { useState, useEffect, useRef } from "react";
import { Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetLatestArticles } from "../../../hooks/useGetLatestArticles";
import SimilarArticleDrawer from "../../similarArticlesPage/similarArticleDrawer";
import ScrollToTopBtn from "../../../components/scrollToTopBtn";
import Header from "./header";
import Body from "./body";

interface ArticlesComponentProps {
  pageName: string;
  search: string;
  selectedCategories: string[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  sortType: string;
  setSortType: React.Dispatch<React.SetStateAction<string>>;
  selectedCountries: string[];
  setSelectedCountries: React.Dispatch<React.SetStateAction<string[]>>;
  selectedSites: string[];
  setSelectedSites: React.Dispatch<React.SetStateAction<string[]>>;
}

const ArticlesComponent: React.FC<ArticlesComponentProps> = ({
  pageName,
  search,
  page,
  setPage,
  sortType,
  setSortType,
  selectedCategories,
  selectedCountries,
  setSelectedCountries,
  selectedSites,
  setSelectedSites,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const loadMoreRef = useRef<HTMLButtonElement | null>(null);

  const { loading, articles, error } = useGetLatestArticles(
    page,
    "publish_date",
    sortType,
    "en",
    search,
    selectedCategories,
    selectedCountries,
    selectedSites
  );

  const handleCountrySelect = (country: string) => {
    setPage(1);
    setSelectedCountries((prev) => {
      return prev.includes(country) ? prev : [...prev, country];
    });
  };

  const handleSiteSelect = (site: string) => {
    setPage(1);
    setSelectedSites((prev) => {
      return prev.includes(site) ? prev : [...prev, site];
    });
  };

  const handleCategorySelect = (category: string) => {
    setPage(1);
    // setSelectedCategories((prev) => {
    //   return prev.includes(category) ? prev : [...prev, category];
    // });
  };

  const loadMoreArticles = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [selectedArticleDocId, setSelectedArticleDocId] = useState<string>("");
  const [selectedArticleTitle, setSelectedArticleTitle] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const docId = params.get("id");
    const title = params.get("title");

    if (docId && title) {
      setSelectedArticleDocId(docId);
      setSelectedArticleTitle(title);
      setShowDrawer(true);
    } else {
      setShowDrawer(false);
      setSelectedArticleDocId("");
      setSelectedArticleTitle("");
    }
  }, [location.search]);

  const handleDrawerOpen = (docId: string, title: string) => {
    setSelectedArticleDocId(docId);
    setSelectedArticleTitle(title);
    setShowDrawer(true);

    const params = new URLSearchParams(location.search);
    params.set("id", docId);
    params.set("title", title);
    navigate({ search: params.toString() });
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
    setSelectedArticleDocId("");
    setSelectedArticleTitle("");

    const params = new URLSearchParams(location.search);
    params.delete("id");
    params.delete("title");
    navigate({ search: params.toString() });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading) {
          loadMoreArticles();
        }
      },
      {
        rootMargin: "2000px",
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loading]);

  return (
    <>
      <Container
        sx={{ marginTop: "1rem", marginBottom: "4rem", position: "relative" }}
      >
        <Header
          pageName={pageName}
          search={search}
          selectedCategories={selectedCategories}
          sortType={sortType}
          setSortType={setSortType}
        />

        <Body
          articles={articles}
          loading={loading}
          error={error}
          handleCategorySelect={handleCategorySelect}
          handleCountrySelect={handleCountrySelect}
          handleSiteSelect={handleSiteSelect}
          handleDrawerOpen={handleDrawerOpen}
          loadMoreArticles={loadMoreArticles}
          loadMoreRef={loadMoreRef}
        />

        <SimilarArticleDrawer
          showDrawer={showDrawer}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          selectedArticleDocId={selectedArticleDocId}
          selectedArticleTitle={selectedArticleTitle}
          handleCategorySelect={handleCategorySelect}
          handleCountrySelect={handleCountrySelect}
          handleSiteSelect={handleSiteSelect}
        />
      </Container>

      <ScrollToTopBtn />
    </>
  );
};

export default ArticlesComponent;
