import { Box, IconButton } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import { useEffect, useState } from "react";

const ScrollToTopBtn = () => {
  const [scrollY, setScrollY] = useState<number>(0);

  const handleScroll = () => setScrollY(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        position: "fixed",
        bottom: "3rem",
        right: "2vw",
        zIndex: 10,
      }}
    >
      {scrollY > 100 && (
        <IconButton
          sx={{
            borderRadius: "50px",
            background: "#ffffff",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            border: "1px solid #ccccccb0",
            color: "#333",
            transition: "all 0.2s ease",
            "&:hover": {
              background: "#f1f1f1",
            },
          }}
          title="Scroll to top"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <ArrowUpward />
        </IconButton>
      )}
    </Box>
  );
};

export default ScrollToTopBtn;
