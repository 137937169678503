import { useLocation } from "react-router-dom";
import ArticlesComponent from "./articleComponent/articlesComponent";
import { useEffect, useState } from "react";

const SearchPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get("q");

  const [search, setSearch] = useState<string>(query || "");
  const [sortType, setSortType] = useState("desc");
  const [page, setPage] = useState<number>(1);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedSites, setSelectedSites] = useState<string[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSearch(query || "");
    setPage(1);
    setSortType("desc");
  }, [query]);

  return (
    <ArticlesComponent
      pageName={"search"}
      search={search}
      page={page}
      setPage={setPage}
      sortType={sortType}
      setSortType={setSortType}
      selectedCategories={selectedCategories}
      selectedCountries={selectedCountries}
      setSelectedCountries={setSelectedCountries}
      selectedSites={selectedSites}
      setSelectedSites={setSelectedSites}
    />
  );
};

export default SearchPage;
