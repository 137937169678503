import { Box, Typography, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: {
          xs: "calc(100vh - 64px)",
          md: "100vh",
        },
        textAlign: "center",
        bgcolor: "background.default",
        p: 3,
      }}
    >
      <Box sx={{ mb: 3 }}>
        <ErrorOutlineIcon sx={{ fontSize: "10rem", color: "primary.main" }} />
      </Box>
      <Typography
        variant="h1"
        sx={{ fontSize: "3rem", fontWeight: "bold", mb: 2 }}
      >
        404
      </Typography>
      <Typography variant="h6" sx={{ color: "text.secondary", mb: 3 }}>
        Oops! The page you are looking for does not exist.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoHome}>
        Go to Home
      </Button>
    </Container>
  );
};

export default NotFoundPage;
