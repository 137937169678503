import React, { useState, useEffect } from "react";
import { ApiResponse } from "../types";

export const useGetLatestArticles = (
  // adding a commment
  // to demonstrate merge conflicts

  page: number = 1,
  sortBy: string = "publish_date",
  sortType: string = "asc",
  language: string = "en",
  search: string = "",
  selectedCategories: string[] = [],
  selectedCountries: string[] = [],
  selectedSites: string[] = []
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [articles, setArticles] = useState<ApiResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [lastParams, setLastParams] = useState({
    sortBy,
    sortType,
    language,
    search,
    selectedCategories,
    selectedCountries,
    selectedSites,
  });

  useEffect(() => {
    const fetchLatest = async () => {
      setLoading(true);
      try {
        // Join categories into a single string for the URL
        const categoryString = selectedCategories.join("+");
        const countryString = selectedCountries.join("+");
        const siteString = selectedSites.join("+");
        const apiUrl = process.env.REACT_APP_API_URL;
        let url = `${apiUrl}/api/latest/?page=${page}&pageSize=20`;

        const params = [
          `sortBy=${sortBy}`,
          `sortType=${sortType}`,
          `language=${language}`,
          search && `search=${encodeURIComponent(search)}`,
          !search &&
            selectedCategories.length > 0 &&
            `category=${encodeURIComponent(categoryString)}`,
          selectedCountries.length > 0 &&
            `country=${encodeURIComponent(countryString)}`,
          selectedSites.length > 0 && `site=${encodeURIComponent(siteString)}`,
        ]
          .filter(Boolean)
          .join("&");

        url += `&${params}`;

        const response = await fetch(url);
        const data: ApiResponse = await response.json();

        if (
          sortBy !== lastParams.sortBy ||
          sortType !== lastParams.sortType ||
          language !== lastParams.language ||
          search !== lastParams.search ||
          selectedCategories.join("+") !==
            lastParams.selectedCategories.join("+") ||
          selectedCountries.join("+") !==
            lastParams.selectedCountries.join("+") ||
          selectedSites.join("+") !== lastParams.selectedSites.join("+")
        ) {
          // Reset articles if any parameter changes
          setArticles(data || null);
          setLastParams({
            sortBy,
            sortType,
            language,
            search,
            selectedCategories,
            selectedCountries,
            selectedSites,
          });
        } else if (page !== 1) {
          // Append articles if only 'page' changes
          setArticles((prevArticles) => ({
            ...data,
            documents: [
              ...(prevArticles?.documents || []),
              ...(data.documents || []),
            ],
          }));
        } else {
          setArticles(data || null);
        }
      } catch (error: any) {
        setError(error.toString());
        setArticles(null);
      } finally {
        setLoading(false);
      }
    };

    fetchLatest();
  }, [
    page,
    sortBy,
    sortType,
    language,
    search,
    selectedCategories,
    selectedCountries,
    selectedSites,
  ]);

  return { loading, articles, error };
};
