export interface BookmarkInterface {
  article_id: string;
  timestamp: string;
  user: number;
}
export class Bookmark {
  async addBookmark(article_id: string): Promise<BookmarkInterface | null> {
    console.log(article_id);
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      throw new Error("User is not authenticated.");
    }
    try {
      const addUserBookmark = await fetch(
        `${process.env.REACT_APP_API_URL}/api/bookmarks/`,
        {
          method: "POST",
          headers: {
            "Authorization": `Token ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify( {article_id} ),

        }
      );
      if (!addUserBookmark.ok) {
        throw new Error("Failed to fetch bookmarks.");
      }
      const data = await addUserBookmark.json();
      return data;
    } catch (error) {
      console.error("Error while adding bookmarks :", error);
      return null;
    }
  }

  async getBookmark(): Promise<BookmarkInterface | null> {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      throw new Error("User is not authenticated.");
    }
    try {
      const getUserBookmark = await fetch(
        `${process.env.REACT_APP_API_URL}/api/bookmarks/`,
        {
          method: "GET",
          headers: {
            "Authorization": `Token ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!getUserBookmark.ok) {
        throw new Error("Failed to fetch bookmarks.");
      }
      const data = await getUserBookmark.json();
      return data;
    } catch (error) {
      console.error("Error while fetching bookmarks :", error);
      return null;
    }
  } 

  async deleteBookmark(pk: string): Promise<boolean> {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      throw new Error("User is not authenticated.");
    }
    try {
      const deleteUserBookmark = await fetch(
        `${process.env.REACT_APP_API_URL}/api/bookmarks/${pk}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${authToken}`,
          },
        }
      );
      if (!deleteUserBookmark.ok) {
        if (deleteUserBookmark.status === 404) {
          throw new Error("Bookmark not found.");
        }
        throw new Error("Failed to delete bookmark.");
      }
      return true;
    } catch (error) {
      console.error("Error while deleting bookmark:", error);
      return false;
    }
  }

}

export const bookmarkService = new Bookmark();
