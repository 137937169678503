import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import DummyCard from "../../components/dummycard";
import { bookmarkService } from "../../userAPI/bookmarks";
import { useAppDispatch } from "../../redux/store/store";
import authService from "../../userAPI/auth";
import { UserInterface } from "../../redux/store/authSlice";
import { login as storeLogin } from "../../redux/store/authSlice";

import {
  addBookmark,
  BookmarkInterface,
} from "../../redux/store/bookmarksSlice";
import ArticleCard from "../../components/card";

function BookmarkPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [article, setArticles] = useState<any[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const u = async () => {
      setLoading(true);
      setError(null);
      try {
        const user = await authService.getCurrentUser();
        if (user && isUserInterface(user)) {
          dispatch(storeLogin(user));}
        const fetchedBookmarks: any = await bookmarkService.getBookmark();
        if (fetchedBookmarks) {
          dispatch(addBookmark(fetchedBookmarks));
          fetchedBookmarks.map(async (bookmark: BookmarkInterface) => {
            const articleData = await fetch(
              `${process.env.REACT_APP_API_URL}/api/article/${bookmark.article_id}`
            );
            const data = await articleData.json();
            setArticles((prev: any[]) => [...prev, data]);
          });
        } else {
          setError("Failed to fetch bookmarks.");
        }
      } catch (error) {
        setError("An error occurred while fetching bookmarks.");
      } finally {
        setLoading(false);
      }
    };
    // return () => {
      u();
    // };
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1vw",
          padding: "calc(.2rem + .2vw) calc(.5rem + .5vw)",
          background: "white",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        {" "}
        <h1>My Bookmarks</h1>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            overflowX: "auto",
            overflowY: "hidden",
            padding: "0 .5rem 1rem .5rem",
          }}
        >
          {Array.from({ length: 4 }).map((_, index2) => (
            <DummyCard key={index2} />
          ))}
        </Box>
      </Box>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            padding: "1rem 2rem",
            fontSize: "calc(1.5rem + .8vw)",
            fontWeight: "bold",
            color: "#34495e",
            textTransform: "capitalize",
            wordBreak: "break-word",
          }}
        >
          Bookmarks
        </Typography>
      </Box>
      <Box
        gap={4}
        p={4}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: {
            xs: "column",
            sm: "row",
            lg: "row",
          },
        }}
      >
        {article.map((singleArticle: any, index: number) => (
          <Box
            sx={{
              flex: {
                sm: "0 0 45%",
                lg: "0 0 30%",
              },
            }}
            key={index}
          >
            <ArticleCard
              article={singleArticle.documents[0]}
              onCategorySelect={() => {}}
              onCountrySelect={() => {}}
              onSiteSelect={() => {}}
              isBookmarked={true}
              showSimilarArticlesButton={false}
            />
          </Box>
        ))}
      </Box>
    </>
  );
}
export default BookmarkPage;

function isUserInterface(obj: any): obj is UserInterface {
  return (
    typeof obj === "object" &&
    typeof obj.id === "number" &&
    typeof obj.username === "string" &&
    typeof obj.email === "string" &&
    typeof obj.dateJoined === "string" &&
    typeof obj.isActive === "boolean"
  );
}
