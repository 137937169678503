import { Box } from "@mui/material";
import React, { useState } from "react";
import Sidebar from "./sidebar";
import Navbar from "./navbar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [focusOnSearch, setFocusOnSearch] = useState<boolean>(false);

  const toggleSidebar = (shouldFocusOnSearch: boolean = false) => {
    setIsSidebarOpen((prev) => !prev);
    if (shouldFocusOnSearch === true) setFocusOnSearch(true);
    else setFocusOnSearch(false);
  };

  return (
    <Box
      id="layout"
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Navbar toggleSidebar={toggleSidebar} />

      <Sidebar
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        focusOnSearch={focusOnSearch}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: {
            xs: "100%",
            md: "calc(100% - 250px - 0rem)",
          },
          marginLeft: {
            md: "auto",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
