class AuthService {
  async signUp(
    email: string,
    username: string,
    password: string
  ): Promise<string | null> {
    try {
      const accountCreation = await fetch(
        process.env.REACT_APP_API_URL+"/api/signup/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, username, password }),
        }
      );
      if (!accountCreation.ok) {
        const errorData = await accountCreation.json();
        console.log(errorData);
        let errorMessage = '';

        if (errorData.username) {
          errorMessage += errorData.username.join(' ') + ' ';
        }
        if (errorData.password) {
          errorMessage += errorData.password.join(' ') + ' ';
        }
    
    
        throw new Error(errorMessage.trim());
      }

      const data = await accountCreation.json();
      console.log("Signup response data:", data);
      const user = await this.login(username, password);
      console.log("User login result:", user);
      return user;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Failed to Signup user: Unknown error");
      }
    }
  }

  async login(username: string, password: string): Promise<string | null> {
    try {
      const userLogin = await fetch(
        `${process.env.REACT_APP_API_URL}/api/login/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password, username }),
        }
      );
      if (!userLogin.ok) {
        if (userLogin.status === 400) {
          // Handle unauthorized (invalid credentials)
          throw new Error("invalid credential");
        } else {
          // Handle other types of HTTP errors
          throw { error: `HTTP error! status: ${userLogin.status}` };
        }
      }
      const data = await userLogin.json();
      console.log("Login Info : ", data);
      localStorage.setItem("authToken", data.token);
      return data;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Failed to login user: Unknown error");
      }
    }
  }

  async logout(): Promise<string | null> {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      throw new Error("User is not authenticated.");
    }
    try {
      const userLogout = await fetch(
        `${process.env.REACT_APP_API_URL}/api/logout/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!userLogout.ok) {
        throw new Error("Failed to logout user.");
      }
      localStorage.removeItem("authToken");
      return "Logout successful.";
    } catch (error) {
      console.error("Error during logout:", error);
      return null;
    }
  }

  async getCurrentUser(): Promise<string | null> {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      throw new Error("User is not authenticated.");
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/profile/`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          "Failed to get current user information : " + errorData
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Internal error occur.Try again later.");
    }
  }
}

const authService = new AuthService();
export default authService;
