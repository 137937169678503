import React from "react";
import { RouterProvider } from "react-router-dom";
import theme from "./theme";
import { router } from "./routes";
import { ThemeProvider } from "@emotion/react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./index.css";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position="top-right" autoClose={3500} />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
