import React from "react";
import Box from "@mui/material/Box";

interface DummyCardProps {
  styles?: object;
  onClick?: any;
}

const DummyCard: React.FC<DummyCardProps> = ({ styles, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexShrink: "0",
        width: "100%",
        maxWidth: {
          xs: "250px",
          md: "300px",
        },
        height: {
          xs: "70vh",
          sm: "30em",
        },
        maxHeight: "30em",
        perspective: 1000,
        background: "transparent",
        borderRadius: "15px",
        ...styles,
      }}
      onClick={() => onClick && onClick()}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          textAlign: "center",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          background: "transparent",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: "15px",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default DummyCard;
