import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/layout";
import SimilarArticlesPage from "./pages/similarArticlesPage";
import HomePage from "./pages/home";
import CategoryPage from "./pages/articlesPage/categoryPage";
import SearchPage from "./pages/articlesPage/searchPage";
import NotFoundPage from "./pages/notFoundPage";
import BookmarkPage from "./pages/userBookmarksPage";
export const router = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <Layout children={<HomePage />} />,
  // },
  {
    path: "/",
    element: <Layout children={<CategoryPage />} />,
  },
  {
    path: "/categories",
    element: <Layout children={<CategoryPage />} />,
  },
  {
    path: "/articles/:id/:title",
    element: <SimilarArticlesPage />,
  },
  {
    path: "/search",
    element: <Layout children={<SearchPage />} />,
  },
  {
    path: "/bookmarks",
    element: <Layout children={<BookmarkPage />} />,
  },
  {
    path: "*",
    element: <Layout children={<NotFoundPage />} />,
  },
]);
