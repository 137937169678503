import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  Treemap,
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
} from "recharts";
import { Grid, Paper, Typography, Box, useTheme } from "@mui/material";
import { Article } from "../../../types";
import {
  format,
  parse,
  eachDayOfInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { useNavigate } from "react-router-dom";

interface CategoryCount {
  name: string;
  value: number;
  color: string;
}

interface NewsStatisticsProps {
  documents: Article[];
}

const NewsStatistics: React.FC<NewsStatisticsProps> = ({ documents }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.info.main,
  ];

  // Categories chart data
  const categoryCounts = documents.reduce(
    (acc: Record<string, number>, doc) => {
      let categories: string[] = [];

      if (doc.categories && typeof doc.categories === "string") {
        categories = doc.categories

          .split(",")
          .map((cat) => cat.trim().replace(/^'|'$/g, ""));
      } else if (Array.isArray(doc.categories)) {
        categories = doc.categories;
      }

      categories.forEach((category) => {
        if (typeof category === "string" && category) {
          acc[category] = (acc[category] || 0) + 1;
        }
      });

      return acc;
    },
    {}
  );

  const CustomTreemapContent = (props: any) => {
    const { depth, x, y, width, height, index, name, value } = props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: depth < 2 ? categoryData[index].color : "none",
            cursor: "pointer",
          }}
        />
        {depth === 1 && width > 50 && height > 30 && (
          <>
            <text
              onClick={() => navigate(`/categories?category=${name}`)}
              x={x + width / 2}
              y={y + height / 2 - 7}
              textAnchor="middle"
              fill="#fff"
              fontSize={11}
            >
              {name}
            </text>
            <text
              x={x + width / 2}
              y={y + height / 2 + 12}
              textAnchor="middle"
              fill="#fff"
              fontSize={11}
            >
              {value}
            </text>
          </>
        )}
      </g>
    );
  };

  const categoryData: CategoryCount[] = Object.entries(categoryCounts)
    .map(([name, value], index) => ({
      name,
      value,
      color: COLORS[index % COLORS.length], // This ensures we cycle through the colors
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 15);

  const dateData = (() => {
    const dates = documents.map((doc) => new Date(doc.publish_date));
    const minDate = new Date(Math.min(...dates.map((d) => d.getTime())));
    const maxDate = new Date(Math.max(...dates.map((d) => d.getTime())));

    const allDates = eachDayOfInterval({
      start: startOfDay(minDate),
      end: endOfDay(maxDate),
    });

    const dateCounts = allDates.map((date) => {
      const count = documents.filter(
        (doc) =>
          startOfDay(new Date(doc.publish_date)).getTime() ===
          startOfDay(date).getTime()
      ).length;
      return {
        date: format(date, "yyyy-MM-dd"),
        count: count,
      };
    });

    return dateCounts;
  })();

  const siteData: CategoryCount[] = documents.reduce(
    (acc: CategoryCount[], doc) => {
      const existingSite = acc.find((item) => item.name === doc.site);
      if (existingSite) {
        existingSite.value += 1;
      } else {
        acc.push({
          name: doc.site,
          value: 1,
          color: COLORS[acc.length % COLORS.length],
        });
      }
      return acc;
    },
    []
  );

  const renderChart = (title: string, chart: React.ReactElement) => (
    <Grid item xs={12} md={6} sx={{ mt: 2 }}>
      <Paper sx={{ p: 1, height: "100%" }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "900", color: theme.palette.text.secondary }}
        >
          {title}
        </Typography>
        <Box height={500}>
          <ResponsiveContainer width="100%" height="100%">
            {chart}
          </ResponsiveContainer>
        </Box>
      </Paper>
    </Grid>
  );

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0];
      if (data.dataKey === "count") {
        return (
          <Paper sx={{ p: 1 }}>
            <Typography variant="body2">
              {`Date: ${format(
                parse(label, "yyyy-MM-dd", new Date()),
                "MMMM d, yyyy"
              )}`}
            </Typography>
            <Typography variant="body2">{`Count: ${data.value}`}</Typography>
          </Paper>
        );
      } else if (data.payload) {
        return (
          <Paper sx={{ p: 1 }}>
            <Typography variant="body2">
              {`${data.payload.name}: ${data.payload.value}`}
            </Typography>
          </Paper>
        );
      } else {
        return (
          <Paper sx={{ p: 1 }}>
            <Typography variant="body2">
              {data.name
                ? `${data.name}: ${data.value}`
                : `${label}: ${data.value}`}
            </Typography>
          </Paper>
        );
      }
    }
    return null;
  };

  return (
    <>
      <Box sx={{ pb: 2, pt: 6 }}>
        <Typography
          sx={{
            fontSize: "calc(1.2rem + .3vw)",
            fontWeight: "bold",
            color: "black",
          }}
        >
          Analysis
          <span style={{ fontSize: 11, marginLeft: 10, color: "grey" }}>
            Charts update when more data is loaded
          </span>
        </Typography>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 3 }}>
          {renderChart(
            "Activity on this topic over time",
            <AreaChart data={dateData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                angle={-45}
                textAnchor="end"
                height={70}
                tick={{ fontSize: 15 }}
                tickFormatter={(tick) =>
                  format(parse(tick, "yyyy-MM-dd", new Date()), "MMM, dd yyyy")
                }
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="count"
                stroke={theme.palette.primary.main}
                fill={theme.palette.primary.light}
              />
            </AreaChart>
          )}
          {renderChart(
            "Sources covering this topic",
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={siteData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#8884d8">
                  {siteData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
          {renderChart(
            "Top Categories for this topic",
            <Treemap
              data={categoryData}
              dataKey="value"
              aspectRatio={4 / 3}
              stroke="#fff"
              fill="#8884d8"
              content={<CustomTreemapContent />}
            >
              <Tooltip content={<CustomTooltip />} />
            </Treemap>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default NewsStatistics;
