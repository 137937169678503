import React, { useEffect, useState } from "react";
import { CardMedia, Typography, Box, IconButton, Button } from "@mui/material";
import { Article } from "../types";
import {
  AddCircleOutline,
  BookmarkAddOutlined,
  BookmarkAdded,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import AuthPopUp from "./authPopUp";
import { RootState } from "../redux/store/store";
import { useAppSelector } from "../redux/store/store";
import { bookmarkService } from "../userAPI/bookmarks";

interface ArticleCardProps {
  article: Article;
  onCategorySelect: (category: string) => void;
  onCountrySelect: (country: string) => void;
  onSiteSelect: (site: string) => void;
  showSimilarArticlesButton?: boolean;
  handleDrawerOpen?: (docId: string, title: string) => void;
  small?: boolean;
  isBookmarked?: boolean;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  article,
  onCategorySelect,
  onCountrySelect,
  onSiteSelect,
  showSimilarArticlesButton = true,
  handleDrawerOpen,
  small,
  isBookmarked = false,
}) => {
  const currentTime = new Date();

  const formatDate = (publishDate: string) => {
    const diffInSeconds =
      Math.abs(currentTime.getTime() - new Date(publishDate).getTime()) / 1000;

    let timeElapsed = "";
    if (diffInSeconds < 3600) {
      // Less than one hour
      const minutes = Math.ceil(diffInSeconds / 60);
      timeElapsed = `${minutes}m ago`;
    } else if (diffInSeconds < 86400) {
      // Less than one day
      const hours = Math.ceil(diffInSeconds / 3600);
      timeElapsed = `${hours}h ago`;
    } else if (diffInSeconds < 2592000) {
      // Less than one month
      const days = Math.floor(diffInSeconds / 86400);
      const hours = Math.floor((diffInSeconds % 86400) / 3600);
      timeElapsed = `${days}d ${hours}h ago`;
    } else if (diffInSeconds < 31536000) {
      // Less than one year
      const months = Math.floor(diffInSeconds / 2592000);
      const days = Math.floor((diffInSeconds % 2592000) / 86400);
      timeElapsed = `${months}mo ${days}d ago`;
    } else {
      // One year or more
      const years = Math.floor(diffInSeconds / 31536000);
      const months = Math.floor((diffInSeconds % 31536000) / 2592000);
      timeElapsed = `${years}y ${months}mo ago`;
    }

    return timeElapsed;
  };

  const navigate = useNavigate();
  const titleLength = small ? 60 : 80;
  const summaryLength = small ? 80 : 150;

  const formatDateAndTime = (date: string) => {
    const dateObj = new Date(date);
    return (
      dateObj.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }) +
      " " +
      dateObj.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      })
    );
  };

  const [imageError, setImageError] = useState(false);
  const [isCardFlipped, setIsCardFlipped] = useState<boolean>(false);
  const [loginpopUp, setLoginpopUp] = useState<boolean>(false);

  const handleCardClick = () => {
    setIsCardFlipped(!isCardFlipped);
  };

  // const [isBookmarkAdded, setIsBookmarkAdded] = useState<boolean>(false);
  const [isBookmark, setIsBookmark] = useState<boolean>(isBookmarked);

  const user = useAppSelector((state: RootState) => {
    return state.authReducer.user;
  });

  const storeBookmarks = useAppSelector((state: RootState) => {
    return state.bookmarksReducer.bookmarks;
  });

  useEffect(() => {
    storeBookmarks?.map((bookmark) => {
      if (bookmark.article_id === article.id) {
        setIsBookmark(true);
      }
    });
  }, [storeBookmarks]);

  const handleBookmarkClick = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (user && isBookmark === true) {
      //backend call to delete bookmark
      console.log("deleting");
      const del = await bookmarkService.deleteBookmark(article.id);
      if (!del) {
        setIsBookmark(false);
        console.log("error while deleting bookmark.Try again later.");
        return;
      }
      setIsBookmark(false);
    } else if (user && isBookmark === false) {
      //backend call to save bookmark
      console.log("adding");
      const add = await bookmarkService.addBookmark(article.id);
      if (!add) {
        console.log("error while adding bookmark.Try again later.");
        return;
      }
      setIsBookmark(true);
    } else {
      setLoginpopUp(!loginpopUp);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: {
          xs: "30em",
          sm: "30em",
        },
        maxHeight: "30em",
        perspective: 1000,
        background: "transparent",
        borderRadius: "15px",
      }}
    >
      {loginpopUp && (
        <AuthPopUp open={loginpopUp} onClose={() => setLoginpopUp(false)} />
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          textAlign: "center",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          background: "transparent",
          transition: "transform 0.4s ease-in-out",
          transformStyle: "preserve-3d",
          transform: isCardFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
          borderRadius: "15px",
        }}
        onClick={handleCardClick}
      >
        {/* Front side of card */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            position: "relative",
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderRadius: "15px",
            overflow: "hidden",
            pointerEvents: isCardFlipped ? "none" : "auto",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: "35%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "15px",
              backgroundColor: "rgba(107, 107, 107, 0.8)",
              boxShadow: "0 1px 5px 0px rgba(255, 255, 255, 0.3)",
            }}
            image={imageError ? "/404.jpg" : article.top_image}
            alt={article.title}
            onError={(event) => {
              if (!imageError) {
                const target = event.target as HTMLImageElement;
                target.src = "/404.jpg";
                setImageError(true);
              }
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              borderRadius: "15px",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "15px",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "calc(100% - 2rem)",
            }}
          >
            <Box
              sx={{
                color: "white",
                backgroundColor: "rgba(0, 0, 0)",
                boxShadow: "1px 1px 5px 0 rgba(255, 255, 255, 0.6)",
                padding: "5px 10px",
                borderRadius: "1rem",
                fontSize: "12px",
              }}
            >
              {article.publish_date ? formatDate(article.publish_date) : ""}
            </Box>

            <IconButton
              size="small"
              sx={{
                background: "rgba(255, 255, 255, 0.859)",
                ":hover": {
                  background: "rgb(255,255,255)",
                },
              }}
              onClick={handleBookmarkClick}
            >
              {isBookmark ? <BookmarkAdded /> : <BookmarkAddOutlined />}
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              p: 2,
              maxHeight: "280px",
              textAlign: "left",
              overflow: "hidden",
              "&::-webkit-scrollbar": {
                width: "0.3em",
                height: "0.3em",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: ".9em",
                fontWeight: 400,
                lineHeight: "1",
              }}
            >
              {article.site}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.3em",
                fontWeight: 600,
                lineHeight: "1.6",
              }}
            >
              {article.title}
            </Typography>

            <Typography
              sx={{
                fontSize: "1em",
                fontWeight: 600,
                // overflow: "hidden",
                lineHeight: "1.6",
              }}
            >
              {article.summary}
            </Typography>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: 120,
                opacity: 0.8,
                background:
                  "linear-gradient(rgba(0,0,0,0.01), rgba(255,255,255,1))",
                pointerEvents: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              top: "120px",
              width: "100%",
              height: 40,
              overflowX: "auto",
              display: "flex",
              alignItems: "center",
              padding: 1,
            }}
          >
            {(() => {
              let categories: string[] = [];
              if (typeof article.categories === "string") {
                // Parse the string into an array
                categories = article.categories
                  .replace(/^\[|\]$/g, "") // Remove starting '[' and ending ']'
                  .split(",")
                  .map((item) => item.trim().replace(/^'|'$/g, "")); // Trim whitespace and remove single quotes
              }

              if (categories.length > 0) {
                return categories.map((category: string, index: number) => (
                  <Button
                    key={index}
                    variant="outlined"
                    sx={{
                      borderRadius: 2,
                      minWidth: "auto",
                      fontSize: ".7em",
                      marginRight: 1,
                      whiteSpace: "nowrap",
                      color: "white",
                      borderColor: "white",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                    onClick={() => {
                      navigate(
                        `/categories?category=${encodeURIComponent(category)}`
                      );
                    }}
                  >
                    {category}
                  </Button>
                ));
              } else {
                return <div>No categories to display</div>;
              }
            })()}
          </Box>
        </Box>

        {/* Back side of card */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
            zIndex: "5",
            borderRadius: "15px",
            backgroundColor: "rgb(255, 255, 255)",
            display: "flex",
            flexDirection: "column",
            pointerEvents: isCardFlipped ? "auto" : "none",
          }}
        >
          <Box
            sx={{
              padding: "1.2rem",
              color: "black",
              height: "100%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "fit-content",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 1)",
                  boxShadow: "1px 1px 5px 0 rgba(255, 255, 255, 0.6)",
                  borderRadius: "1rem",
                  fontSize: "12px",
                  padding: "5px 10px",
                  marginBottom: ".6rem",
                }}
              >
                {article.publish_date
                  ? formatDateAndTime(article.publish_date)
                  : ""}
              </Box>

              <IconButton
                size="small"
                onClick={handleBookmarkClick}
                sx={{ display: "none" }}
              >
                {/* {isBookmarked ? <BookmarkAddOutlined />:( isBookmarkAdded ? <BookmarkAdded /> : <BookmarkAddOutlined />)} */}
                {isBookmarked ? (
                  <BookmarkAddOutlined />
                ) : (
                  <BookmarkAddOutlined />
                )}
              </IconButton>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                textAlign: "left",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "0.3em",
                  height: "0.3em",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.3em",
                  fontWeight: 600,
                  lineHeight: "1.6",
                }}
              >
                {article.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: ".8em",
                  fontWeight: 400,
                  lineHeight: "1",
                }}
              >
                <Link
                  to={article.parent_url}
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {article.site}
                </Link>
              </Typography>

              <Typography
                sx={{
                  fontSize: "1.1em",
                  pr: 1,
                  fontWeight: 600,
                  lineHeight: "1.5",
                }}
              >
                {article.summary}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: `${
                  showSimilarArticlesButton ? "space-between" : "flex-end"
                }`,
                width: "100%",
                marginTop: "1rem",
              }}
            >
              {showSimilarArticlesButton && (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    cursor: "pointer",
                    color: "black",
                    fontSize: "1em",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    padding: "5px 10px",
                    borderRadius: "1rem",
                    transition: "all 0.2s",
                    border: "1px solid rgba(255, 255, 255, 0.3)",

                    ":hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.9)",
                      color: "white",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDrawerOpen &&
                      handleDrawerOpen(article.id, article.title);
                  }}
                >
                  <AddCircleOutline fontSize="inherit" />
                  Discover More
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ArticleCard;
