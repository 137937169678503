import { Box, IconButton, InputBase } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";

interface SearchProps {
  toggleSidebar?: () => void;
  focusOnSearch?: boolean;
}

const Search: React.FC<SearchProps> = ({ toggleSidebar, focusOnSearch }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const searchQuery = params.get("q");

  const [searchInput, setSearchInput] = useState(searchQuery || "");

  useEffect(() => {
    setSearchInput(searchQuery || "");
  }, [searchQuery]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchInput) {
      navigate(`/search?q=${searchInput}`);
      toggleSidebar && toggleSidebar();
    }
  };

  const clearSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSearchInput("");
    toggleSidebar && toggleSidebar();
    navigate(`/search`);
  };

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusOnSearch === true && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [focusOnSearch]);

  return (
    <Box
      component="form"
      onSubmit={handleSearch}
      sx={{
        display: "flex",
        alignItems: "center",
        border: searchInput ? "1px solid #3f51b5" : "1px solid #ccc",
        borderRadius: "50px",
        background: "#ffffff",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      }}
    >
      <InputBase
        sx={{ flex: 1, paddingLeft: "1rem" }}
        placeholder="Search..."
        value={searchInput}
        onChange={handleSearchInputChange}
        inputRef={searchInputRef}
      />

      {searchQuery ? (
        <IconButton onClick={clearSearch} sx={{ p: 1 }} title="Clear">
          <CloseIcon />
        </IconButton>
      ) : (
        <IconButton type="submit" sx={{ p: 1 }} title="Search">
          <SearchIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default Search;
