import { useGetSimilarArticles } from "../../../hooks/useGetSimilarArticles";

export const useGetArticlesWithParams = (params: any) => {
  const {
    docId,
    page,
    sortBy,
    sortType,
    language,
    search,
    selectedCategories,
    selectedCountries,
    selectedSites,
    similarity,
  } = params;

  return useGetSimilarArticles(
    docId,
    page,
    sortBy,
    sortType,
    language,
    search,
    selectedCategories,
    selectedCountries,
    selectedSites,
    similarity
  );
};
