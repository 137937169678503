import { useEffect, useState } from "react";
import ArticlesComponent from "./articleComponent/articlesComponent";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux/store/store";
import authService from "../../userAPI/auth";
import { login as storeLogin } from "../../redux/store/authSlice";
import { UserInterface } from "../../redux/store/authSlice";
import { bookmarkService } from "../../userAPI/bookmarks";
import { addBookmark } from "../../redux/store/bookmarksSlice";

const CategoryPage = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(location.search);
  const category = params.get("category") || "trending";

  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [sortType, setSortType] = useState("desc");
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedSites, setSelectedSites] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([
    category,
  ] as string[] | []);

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const user = await authService.getCurrentUser();
        if (user && isUserInterface(user)) {
          dispatch(storeLogin(user));
          const fetchedBookmarks: any = await bookmarkService.getBookmark();
          dispatch(addBookmark(fetchedBookmarks));
        } else {
          console.error("No valid user found.");
        }
      } catch (error) {
        console.error("Failed to get current user:");
      }
    };
      dataFetch();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSelectedCategories([category] as string[] | []);
    setPage(1);
    setSortType("desc");
  }, [category]);

  return (
    <ArticlesComponent
      pageName={"category"}
      search={search}
      page={page}
      setPage={setPage}
      sortType={sortType}
      setSortType={setSortType}
      selectedCategories={selectedCategories}
      selectedCountries={selectedCountries}
      setSelectedCountries={setSelectedCountries}
      selectedSites={selectedSites}
      setSelectedSites={setSelectedSites}
    />
  );
};

function isUserInterface(obj: any): obj is UserInterface {
  return (
    typeof obj === "object" &&
    typeof obj.id === "number" &&
    typeof obj.username === "string" &&
    typeof obj.email === "string" &&
    typeof obj.dateJoined === "string" &&
    typeof obj.isActive === "boolean"
  );
}

export default CategoryPage;
